::-webkit-scrollbar {
  width: 5px;
  background-color: rgb(0, 0, 0);
}
::-webkit-scrollbar-track {
  background-color: rgb(122, 93, 152);
}
::-webkit-scrollbar:hover {
  width: 8px;
}
body {
  background-color: black;
  color: aliceblue;
  font-size: medium;
}
#root {
  min-height: 100vh;
}
.links {
  text-decoration: none;
  color: white;
}
.nav-bar {
  min-width: min-content;
  background-color: rgba(124, 87, 108, 0.816);
  box-shadow: 3px 3px 3px rgb(236, 116, 234);
  display: flex;
  flex-shrink: 4;
  justify-content: space-between;
  align-items: center;
  padding-right: 7px;
}

.nav-bar .nav-icon .logo {
  height: 5rem;
}
.navgroup {
  list-style: none;
  color: rgb(240, 240, 240);
  display: flex;
  padding: 0;
  justify-content: space-around;
  border-radius: 5px;
  box-shadow: 3px 3px rgb(236, 116, 234);
}
.navgroup .navitems {
  background-color: rgb(212, 179, 241);
  color: rgb(0, 0, 0);
  padding: 3px;
  display: block;
  min-width: 80px;
  /* max-width: 70px; */
  /* border-left: 1px solid rgba(0, 0, 0, 0.147); */
  text-align: center;
}

.navgroup :first-child {
  margin: 0px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  border: 0;
}

.navgroup :last-child {
  border: 0;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
}
.navitems:hover {
  cursor: pointer;
  background-color: aqua;
}
.toggle {
  display: none;
  position: absolute;
  margin-top: 2rem;
  right: 2rem;
  width: 31px;
  height: 21px;
}

.toggle .bar {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background-color: aqua;
  margin: 3px;
}

.maincontent {
  display: flex;
  justify-content: center;
  margin: 2px;
  margin-top: 30px;
}
.company {
  box-shadow: 5px 5px 5px 2px rgb(236, 116, 234);
  padding: 6px;
}
.sticker {
  height: 300px;
  width: 300px;
  margin: 10px;
  flex-shrink: 2;
}

.content {
  display: flex;
  height: 30rem;
  background-color: rgb(9, 6, 6);
}

.footerclass {
  position: sticky;
  top: 100%;
  justify-content: center;
  justify-items: stretch;
}

.footerclass .medialinks {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.footerclass .medialinks p {
  padding: 20px;
  color: aliceblue;
}

.footerclass .copyright {
  display: flex;
  justify-content: center;
  gap: 45%;
}

.fileupload {
  padding: 20px;
  border-radius: 15px;
  border: 1px gainsboro dashed;
}
.landingcompamny {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

input::file-selector-button {
  font-weight: bold;
  color: rgb(206, 30, 255);
  padding: 0.5em;
  border: thin solid rgb(154, 0, 250);
  border-radius: 3px;
}

.inputgroup {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}
.labels {
  /* padding-right:70px ; */
}
.signupinput {
  /* width: max-content; */
  padding: 8px;
  font-size: 17px;
}

.fileview .signupinput {
  overflow: visible;
  min-width: 80%;
  border: "unset";
}
.fileview .signupinput:focus-visible {
  outline: unset;
}
.serchbox {
  padding: 10px;
  height: 3rem;
  display: flex;
  justify-content: safe;
  background-color: whitesmoke;
  border-radius: 30px;
  margin-left: 2%;
  margin-right: 2%;
  width: 40%;
  min-width: max-content;
}
.signupbtn {
  width: 30%;
  padding: 10px;
  font-size: large;
  font-weight: bold;
  color: rgb(206, 30, 255);
  border: thin solid rgb(154, 0, 250);
  border-radius: 3px;
  cursor: pointer;
}
.signupbtn:hover {
  background-color: rgb(0, 255, 140);
  transition: ease-in-out;
  transition-delay: 0.2s;
}
.signupbtn:focus {
  background-color: rgb(0, 255, 140);
  transition: ease-in-out;
  transition-delay: 0.2s;
}
.form {
  /* max-width: max-content; */
  min-width: 350px;
  overflow: auto;
}
.plans {
  margin: 10%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.plan {
  width: 30%;
  margin: 10px;
  border: 1px solid;
  border-radius: 20px;
}
.plans .plan .planname {
  background: rgb(102, 20, 234);
  background: linear-gradient(
    90deg,
    rgba(102, 20, 234, 1) 2%,
    rgba(211, 20, 197, 1) 49%,
    rgba(205, 19, 135, 1) 83%
  );
  text-align: left;
  padding: 1px;
  padding-left: 10px;
  margin: 10px;
  border-radius: 10px;
  display: flex;
}
.btnholder {
  width: 70%;
  height: 40px;
}
.plansinput {
  display: none;
}
.planslabel {
  display: block;
}
.plansinput .planslabel {
  cursor: pointer;
}
.plansinput:checked + .plan {
  box-shadow: 4px 4px 4px rgb(0, 255, 153);
}
.plansinput:checked + .plan .checkedimg {
  display: block;
  height: 30px;
}
.checkedimg {
  display: none;
}
.checkiamgecontainer {
  width: 30px;
  height: 30px;
}
.aboutus {
  padding: 10vh;
  height: 70vh;
  background-size: cover;
  /* outline: 1px solid red; */
}

.dashbord {
  display: flex;
  background: rgb(35, 6, 81);
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
}
.folder {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: rgb(0, 0, 62);
  margin: 1%;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px;
}

.foldercheckbox:checked + .planslabel .folder {
  background-color: rgb(18, 24, 142);
}
.fileview {
  width: 79vw;
  background-color: rgb(50, 1, 52);
  margin-top: 30px;
  margin-left: 30px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 84vh;
  overflow: scroll;
  overflow-x: visible;
}
.viewitem {
  border: 1px solid;
  padding: 10px;
  display: "flex";
  justify-content: "space-between";
  align-items: center;
}

.viewitem:hover {
  background-color: rgba(0, 0, 0, 0);
}

.fileviewselectinp:checked + .fileviewselectlabel {
  outline: 1px red;
  background-color: rgba(0, 255, 255, 0.445);
}
/* div {
  outline: 1px solid red;
} */

@media (max-width: 680px) {
  .toggle {
    display: block;
  }
  .navinp:checked + .toggle + .navgroup {
    display: block;
  }
  .nav-bar {
    flex-direction: column;
  }
  .navgroup {
    flex-direction: column;
    width: 100%;
  }
  .navgroup .navitems {
    border: 1px solid rgb(102, 49, 102);
    margin: 0px;
    border-radius: 10px;
  }
  .navgroup {
    display: none;
  }
  .plans {
    margin: 10px;
    flex-direction: column;
    display: block;
  }
  .plan {
    display: block;
    width: 200px;
    padding: 10px;
  }
  .maincontent {
    flex-direction: column;
    margin: 0px;
    justify-content: center;
  }
  .sticker {
    display: none;
  }
  .sidebar{
    display: none;
  }
  .fileview{
    margin: 0px;
    width: 100%;
    height: 100%;
  }
  .fileview .signupinput{
    min-width: unset;
  }
}
